.hh-grayBox {
    background-color: #f8f8f8;
    margin-bottom: 20px;
    padding: 35px;
    margin-top: 20px;
}
.pt45 {
    padding-top: 45px;
}
.order-tracking {
    text-align: center;
    width: 33.33%;
    position: relative;
    display: block;
}
.order-tracking .is-complete {
    display: block;
    position: relative;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 0px solid #afafaf;
    background-color: #f7be16;
    margin: 0 auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
    /* z-index: 2; */
}
.order-tracking .is-complete:after {
    display: block;
    position: absolute;
    content: "";
    height: 14px;
    width: 7px;
    top: -2px;
    bottom: 0;
    left: 5px;
    margin: auto 0;
    border: 0px solid #afafaf;
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
}
.order-tracking.completed .is-complete {
    border-color: #27aa80;
    border-width: 0px;
    background-color: #27aa80;
}
.order-tracking.completed .is-complete:after {
    border-color: #fff;
    border-width: 0px 3px 3px 0;
    width: 7px;
    left: 11px;
    opacity: 1;
}
.order-tracking p {
    color: #a4a4a4;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 20px;
}
.order-tracking p span {
    font-size: 14px;
}
.order-tracking.completed p {
    color: #000;
}
.order-tracking::before {
    content: "";
    display: block;
    height: 3px;
    width: calc(100% - 40px);
    background-color: #f7be16;
    top: 13px;
    position: absolute;
    left: calc(-50% + 20px);
    /* z-index: 0; */
}

.order-tracking-rev::after {
    content: "";
    display: block;
    height: 3px;
    width: calc(100% - 40px);
    background-color: #f7be16;
    top: 13px;
    position: absolute;
    left: calc(-50% + 20px);
    /* z-index: 0; */
}

.order-tracking:first-child:before {
    display: none;
}
.order-tracking.completed:before {
    background-color: #27aa80;
}
.div-boder-completed {
    background-color: #27aa80;
    width: 3px;
    height: 80px;
}

.div-boder-pending {
    background-color: #f7be16;
    width: 3px;
    height: 80px;
}

/* new  */

.order-tracking-revs {
    text-align: center;
    width: 33.33%;
    position: relative;
    display: block;
}
.order-tracking-revs .is-complete {
    display: block;
    position: relative;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 0px solid #afafaf;
    background-color: #f7be16;
    margin: 0 auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
    /* z-index: 2; */
}
.order-tracking-revs .is-complete:after {
    display: block;
    position: absolute;
    content: "";
    height: 14px;
    width: 7px;
    top: -2px;
    bottom: 0;
    left: 5px;
    margin: auto 0;
    border: 0px solid #afafaf;
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
}
.order-tracking-revs.completed .is-complete {
    border-color: #27aa80;
    border-width: 0px;
    background-color: #27aa80;
}
.order-tracking-revs.completed .is-complete:after {
    border-color: #fff;
    border-width: 0px 3px 3px 0;
    width: 7px;
    left: 11px;
    opacity: 1;
}
.order-tracking-revs p {
    color: #a4a4a4;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 20px;
}
.order-tracking-revs p span {
    font-size: 14px;
}
.order-tracking-revs.completed p {
    color: #000;
}
.order-tracking-revs::after {
    content: "";
    display: block;
    height: 3px;
    width: calc(100% - 40px);
    background-color: #f7be16;
    top: 13px;
    position: absolute;
    left: calc(-50% + 20px);
    /* z-index: 0; */
}

.order-tracking-revs-rev::after {
    content: "";
    display: block;
    height: 3px;
    width: calc(100% - 40px);
    background-color: #f7be16;
    top: 13px;
    position: absolute;
    left: calc(-50% + 20px);
    /* z-index: 0; */
}

.order-tracking-revs:last-child:after {
    display: none;
}
.order-tracking-revs.completed:after {
    background-color: #27aa80;
}
