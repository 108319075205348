.select-bar {
    font-size: 14px;
    border-radius: 5px;
    border-color: #e5e7eb;
    max-height: 34px !important;
    min-height: 34px !important;

    & .css-13cymwt-control {
        border-color: #e5e7eb;
        max-height: 34px !important;
        min-height: 34px !important;
    }

    & .css-13cymwt-control:hover {
        border-color: #e5e7eb;
    }

    & .css-1im77uy-control {
        max-height: 34px !important;
        min-height: 34px !important;
    }
    & .css-1r526jw-control {
        max-height: 34px !important;
        min-height: 34px !important;
    }

    & .css-ih4xx5-control:hover {
        border-radius: 5px;
    }

    & .css-13qatl9-multiValue {
        border-radius: 5px;
    }

    & .css-1mbxqx5-MultiValueRemove {
        border-color: #e5e7eb;
        border-radius: 5px;
    }
}

.select-bar-multi {
    font-size: 14px;
    border-radius: 5px;
    border-color: #e5e7eb;
    /* max-height: 34px !important; */
    min-height: 34px !important;

    & .css-13cymwt-control {
        border-color: #e5e7eb;
        /* max-height: 34px !important; */
        min-height: 34px !important;
    }

    & .css-13cymwt-control:hover {
        border-color: #e5e7eb;
    }

    & .css-1im77uy-control {
        /* max-height: 34px !important; */
        min-height: 34px !important;
    }
    & .css-1r526jw-control {
        /* max-height: 34px !important; */
        min-height: 34px !important;
    }

    & .css-ih4xx5-control:hover {
        border-radius: 5px;
    }

    & .css-13qatl9-multiValue {
        border-radius: 5px;
    }

    & .css-1mbxqx5-MultiValueRemove {
        border-color: #e5e7eb;
        border-radius: 5px;
    }
}

/* other css */
.modal-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: white;
    padding-bottom: 10px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
}

.coman-modal-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
}
.coman-modal {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
}

.coman-modal-text {
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
}

.modal-btn-comman {
    padding: 4px;
    border: 1px solid;
    border-radius: 6px;
}

.modal-btn-dwl {
    background-color: #643c94;
    color: white;
}

.modal-btn-cnl {
    background-color: white;
    color: #643c94;
}

/* ======= */
.modal-img {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding-bottom: 10px;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
}

.modal-overlay-img {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.coman-modal-img {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    padding: 5px;
}

.coman-modal-img-end {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 5px;
}

.pdf-viewer {
    width: 90%;
    height: 90%; /* Adjust the height as needed */
}
